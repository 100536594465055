<template>
  <v-card>
    <v-card-title>
      {{ $vuetify.lang.t('$vuetify.equipmentTemplate.label') }}
    </v-card-title>
    <v-card-text>
      <search-form @submit="handleSearchFormSubmit"/>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="equipmentTemplates"
        class="mt-1"
        item-key="name"
        show-select
        single-select
        hide-default-footer
      >

        <template v-slot:item.arrdess="{ item }">
          {{ item.ptCountry }} - {{ item.ptArea }} - {{ item.ptVillage }} - {{ item.ptGroup }}
        </template>

        <template v-slot:item.type="{ item }">
           {{ item.type.paramName }}
        </template>

      </v-data-table>
      <v-pagination
        v-model="queryParams.params.page"
        :length="queryParams.params.pageCount"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="info darken-1"
        @click="handleCancel"
      >
         {{$vuetify.lang.t('$vuetify.common.cancel')}}
      </v-btn>
      <v-btn
        v-if="selected.length > 0"
        color="primary darken-1"
        @click="handleSubmit"
      >
         {{$vuetify.lang.t('$vuetify.common.submit')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  export default {
    components: {
      SearchForm: () => import('./SearchForm')
    },
    data () {
      return {
        selected: [],
        desserts: [],
        dialogEquipmentList: false,
        queryParams: {
          params: {
            page: 1,
            pageCount: 1
          }
        }
      }
    },
    computed: {
      ...mapGetters({
        equipmentTemplates: 'equipmentTemplate/equipmentTemplates'
      }),
      headers () {
        return [
          {
            text: this.$vuetify.lang.t('$vuetify.equipmentTemplate.name'),
            align: 'start',
            sortable: false,
            value: 'name',
          },{
            text: this.$vuetify.lang.t('$vuetify.equipmentTemplate.type'),
            align: 'start',
            sortable: false,
            value: 'type',
          },{
            text: this.$vuetify.lang.t('$vuetify.equipmentTemplate.company'),
            align: 'start',
            sortable: false,
            value: 'company',
          },{
            text: this.$vuetify.lang.t('$vuetify.equipmentTemplate.companyAddr'),
            align: 'start',
            sortable: false,
            value: 'companyAddr',
          }
        ]
      }
    },

    watch: {
      'queryParams.params.page': {
         handler: function(newValue) {
            let params = Object.assign({}, this.queryParams.params)
            params.page = newValue - 1
            this.equipmentTemplateQuery({params: params, data: this.queryParams.data }).then( (res) => {
              this.queryParams.params.pageCount = res.totalPages
            })
         }
      }
    },

    created () {
      let params = Object.assign({}, this.queryParams.params)
      params.page--
      this.equipmentTemplateQuery({
        params: params,
        data: {}
      }).then( (res) => {
        this.queryParams.params.pageCount = res.totalPages
      })
    },

    methods: {
      ...mapActions ({
        equipmentTemplateQuery: 'equipmentTemplate/query'
      }),

      handleDistribute  () {
        this.dialogEquipmentList = true
      },

      handleSearchFormSubmit(data) {
        this.queryParams.data = data
        let params = Object.assign({}, this.queryParams.params)
        params.page = 0
        this.equipmentTemplateQuery({params: params, data }).then( (res) => {
          this.queryParams.params.pageCount = res.totalPages
        })
      },
      handleSubmit () {
        this.$emit('submit', this.selected[0])
      },
      handleCancel () {
        this.$emit('cancel', true)
      }
    }
  }
</script>
